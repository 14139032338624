.wallet-adapter-button {
  background-color: #141C24 !important;
  color: white !important;
  font-weight: bold !important;
  height: 44px !important;
  border-radius: 0.5rem !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 0.5rem !important;
  transition: background-color 0.2s !important;
}

.wallet-adapter-modal-list button {
  border-radius: 0.5rem !important;
  padding: 10px 20px !important;
  margin: 5px 0px !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  background: #141C24 !important;
}

.wallet-adapter-modal-list .wallet-adapter-button-start-icon {
  position: relative;
  left: unset;
}

.wallet-adapter-button:hover {
  background-color: #202a35 !important;
}

.wallet-adapter-button-trigger {
  background-color: #141C24 !important;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 50px !important;
  height: 45px !important;
}

.wallet-adapter-modal-wrapper {
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: #000911;
  border-radius: '1rem';
  padding: 30px;
}